const Data = 
[
  {
    "CRN": 22008028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118049,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22148055,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028076,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118040,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118037,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128081,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118039,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22148020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22158004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128072,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128080,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018054,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028073,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118058,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128082,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028088,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028070,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118060,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128089,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018061,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128092,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128091,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118045,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108011,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028087,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108033,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028074,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22048008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128093,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128084,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128090,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118035,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22008012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22018059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118056,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118057,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22008068,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028077,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22108025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118048,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22028075,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22128094,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118036,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22118002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22170036,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010066,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020076,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100038,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110044,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22140067,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120072,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110045,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110022,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22000029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110055,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120030,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120075,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22000027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110043,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22000083,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120078,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22040010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110060,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110056,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120084,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110054,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120080,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010032,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010033,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120087,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22000016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22150007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22010052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020079,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22140050,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110031,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110049,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22000034,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22170035,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22120074,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110064,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020073,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020081,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110023,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110011,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22020077,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22100037,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22110062,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112074,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012035,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012066,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012070,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22042026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22002023,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102049,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112076,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012030,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112048,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102051,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012077,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112068,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112031,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012033,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102022,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102011,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112078,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102054,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112061,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22042039,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22142037,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012075,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22142059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112036,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22002008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102041,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112069,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112073,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102043,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102050,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112060,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112065,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102038,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012034,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012040,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22152012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102055,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22102052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112045,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112064,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22002009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112058,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22002042,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122173,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022160,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122143,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112092,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122147,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012079,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112082,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012108,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122164,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122142,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012111,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112099,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112127,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112123,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122150,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022158,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122170,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122146,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112095,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022161,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022168,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122154,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112116,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112090,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112103,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112112,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112125,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122167,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112101,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122165,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022144,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022156,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112091,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012089,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122163,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122152,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112126,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012114,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112115,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122139,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112121,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112081,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022159,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012113,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022169,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022157,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122153,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112105,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122151,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122171,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122145,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022172,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112096,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22022141,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012110,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112119,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012104,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012098,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112118,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22042117,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122149,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22012107,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112100,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122155,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22122166,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22142086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22112124,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111066,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111062,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22041013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011070,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22021087,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111030,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111057,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111044,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011068,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011011,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22141020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22121088,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111067,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22021084,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011022,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011040,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111031,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22021086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22121083,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111051,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011048,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111043,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111050,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22041003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111063,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011056,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111041,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011065,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011032,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111064,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22011054,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22021085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111061,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111071,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22111023,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22021090,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22101089,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013036,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22173030,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123088,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013054,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013038,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22143002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113040,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123077,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22003028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123075,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123080,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113045,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113023,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113035,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123089,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123074,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123081,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113039,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123083,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22003026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103044,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22023086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22073029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123078,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113034,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113055,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22003020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22023090,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123082,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113037,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013049,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22143058,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013057,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103069,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113051,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013043,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113067,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22013022,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123084,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113060,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22143024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22003006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113061,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22123087,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22103005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22113062,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22003027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114009,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114021,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114039,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014033,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114035,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114034,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014043,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114075,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114058,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114026,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114077,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114030,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114038,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114023,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114059,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114029,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114027,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114020,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114025,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114028,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014061,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114016,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114041,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114019,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114017,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114078,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114050,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114012,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114057,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114032,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114055,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114022,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114056,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114046,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114040,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014024,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114053,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114047,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114048,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114045,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114062,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144036,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114060,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114052,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114013,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014044,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144015,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114018,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014037,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114049,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114051,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114076,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114014,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114031,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114011,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114063,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114042,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014100,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014119,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114130,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114122,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114123,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114101,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114090,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114110,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114091,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124161,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114113,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124163,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22024164,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114118,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114106,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014085,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124172,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114102,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22144115,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22024168,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114087,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114112,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114097,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124160,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22104126,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124171,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124167,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22124166,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014086,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014081,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22104099,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114094,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014109,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114129,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014134,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014135,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114098,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114116,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014127,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114132,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114089,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114131,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114133,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114103,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114125,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22014084,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114096,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114120,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114105,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114121,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114107,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114108,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114124,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22114083,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115002,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115004,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115007,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115006,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115008,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115010,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115001,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115003,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115005,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115048,
    "URL": "https://www.pvgcoet.ac.in/"
  },
  {
    "CRN": 22115009,
    "URL": "https://www.pvgcoet.ac.in/"
  }
]

export default Data;